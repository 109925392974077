<template>
    <div class="label-input" :style="style">
		<BaseCard :base-card="baseCard" class=" h-100">
			<FormulateInput
				:type="type"
				:name="type"
				:value="value"
				:label="label"
				:checked="checked"
				@input="inputChanged"
			/>
		</BaseCard>
        <!-- <FormulateInput v-else :type="type" :name="type" v-model="data.value" :label="data.label"/> -->
    </div>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";

export default {
    name: "LabelInput",
	components: {
		BaseCard,
	},
	props: {
		type: {
			type: String,
			default: 'text'
		},
		label: {
			type: String,
			default: ''
		},
		value: {
			type: [String, Boolean],
			default: ''
		},
		fontFamily: {
			type: String,
			default: 'inherit'
		},
		fontWeight: {
			type: String,
			default: '400'
		},
		fontSize: {
			type: String,
			default: 'inherit'
		},
		baseCard: {
			type: Boolean,
			default: false,
		},
		checked: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			style: {
				"--font-family": this.fontFamily,
				"--font-weight": this.fontWeight,
				"--font-size": this.fontSize,
			}
		}
	},
	methods: {
		inputChanged( value ) {
			this.$emit( 'changed', value );
		},
	},
};
</script>

<style lang='scss' scoped>

::v-deep .formulate-input {

	.formulate-input-wrapper {
		display: flex;
		align-items: center;

		.formulate-input-label {
			font-family: var(--font-family);
			font-weight: var(--font-weight);
			margin-right: 1rem;
			font-size: var(--font-size);
		}

		.formulate-input-element {
			flex-grow: 1;
			max-width: 100%;

			input {
				padding: .5rem .75rem;
			}
		}
	}
}

</style>